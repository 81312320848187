import React from 'react'
import LightboxLayout from '../../components/lightboxLayout'

const Page = () => {
  return (
    <LightboxLayout>
      <h2>WHY YOU NEED TO BRUSH YOUR TEETH</h2>
      <p>Brushing helps prevent  tooth decay</p>
      <p>Dental  plaque  is a soft, sticky  substance that builds  up on your teeth. It is mostly made up of bacteria, which feed on sugar from food and drink, producing acids  as a waste  product. The acids attack the teeth by dissolving the minerals in the tooth  surface. If this happens too often, tooth decay results.  For this reason, you shouldn't have sugary  foods and I or drinks  too often during  the day.</p>
      <p>Minerals in saliva can mend the teeth. If fluoride is present  in the mouth, it helps  teeth to repair  themselves. Fluoride also makes bacteria less able to produce acid. To remove plaque and help teeth to mend  themselves, you need to brush  twice a day with a fluoride  toothpaste.</p>
      <p>Brushing helps prevent  gum disease</p>
      <p>You also need to brush  to stop plaque  damaging your gums.  If plaque is allowed to build up, the bacteria in it can make  your gums  sore and infected. Painless gum pockets will start to form around the teeth - and bone  supporting the teeth will slowly  be lost. If left unchecked, gum disease will lead to the loss of teeth.</p>
      <h3>How should  I brush?</h3>
      <p>Brush your teeth thoroughly twice a day with a soft-to-medium brush and fluoride toothpaste. Replace the brush when the bristles get out of shape.</p>

      <p>Put the bristles at the join between teeth and gums, pointing towards the gums, and brush using short circular movements.</p>
      <p>Brush all round every tooth, carefully making sure you can feel the brush on your gums.</p>
      <p>Don't use too much force -give your teeth and gums a gentle scrub. Thorough brushing takes two or three minutes; why not time yourself?</p>
      <p>After brushing, you should spit out the toothpaste - but do not rinse, as this lessens the effect of the fluoride.</p>
      <p>Small children should only use a small, pea-sized amount of fluoride toothpaste. They cannot brush properly until they are at least six or seven, so an adult should help them brush their teeth. One way is to stand behind the child and tilt their head back so all the teeth can be seen and reached.</p>
    </LightboxLayout>
  )
}

export default Page